import React, { Fragment, useState, useEffect } from 'react';
import Select from '../../common/select-basic'
import Tools from '../../../js/tools'
import notie from 'notie'
import { Box } from 'react-feather';
import Breadcrumb from '../../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone from '../../common/dropzone';
import SelectFetch from '../../common/select-fetch';
import SelectSimple from '../../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../../common/tabla_estatica';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import LoaderSeccion from '../../../components/common/loader_secciones';

const Default = ({ esEditar }) => {
    const [conductor, setConductor] = useState(null)
    const [refreshPrimeraCarga, setRefreshPrimeraCarga] = useState(true)
    const [isUpdating, setIsUpdating] = useState(false);
    const navigate = useNavigate()
    const params = useParams()
    
    const guardar = () => {
        setIsUpdating(true);

        const _conductor = Tools.getForm('formconductor', { claveEtiquetas: '.txtVacios', ignore: [ 'txt_telefono', 'txt_licencia', 'txt_desc'] })

        
        if (_conductor.formIsEmpty) {   
            let mensaje = `${_conductor.formIsEmpty ? _conductor.stringIsEmpty : ''}`
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        // return
        Tools.toolFetch({
            ruta: esEditar ? 'rt_drivin_conductor_editar' : 'rt_drivin_conductor_agregar',
            method: 'POST',
            body: {
                ..._conductor.data,
            },
        }, (response) => {
            if (response.estatus === 1) {
                 notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El conductor ha sido agregado con exito`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    
                })

                setTimeout(() => {
                    window.location.href = '/conductores'
                }, 2000);                
            }
            if (response.estatus === 0) {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `No se ha podido agregar el conductor`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            if (response.estatus === 3) {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ya existe un conductor con este correo`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            setIsUpdating(false);

        })
    }


    const actualizar = (id) => {
        setIsUpdating(true);

        const _conductor = Tools.getForm('formconductor', { claveEtiquetas: '.txtVacios', ignore: [ 'txt_telefono', 'txt_licencia', 'txt_desc'] })
        
        if (_conductor.formIsEmpty) {   
            let mensaje = `${_conductor.formIsEmpty ? _conductor.stringIsEmpty : ''}`
             notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })

            setIsUpdating(false);
            return
        }

        // return
        Tools.toolFetch({
            ruta:  'rt_drivin_conductor_editar' ,
            method: 'POST',
            body: {
                ..._conductor.data,
                id
            },
        }, (response) => {
            console.log(response)
            if (response.estatus === 1) {
                 notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El conductor ha sido actualizado con exito`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    
                })              
            }
            if (response.estatus === 3) {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
           
            if (response.estatus == 0) {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'No se pudo actualizar la información del conductor. Por favor, inténtelo más tarde.',
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setIsUpdating(false);

        })
    }


    function validarNumeros(input) {
        input.target.value = input.target.value.replace(/[^0-9]/g, '');
    }

    useEffect(() => {
        let isSubscribed = true

        if (esEditar) {
            return Promise.all([
                fetch('/rt_drivin_conductor_detalle', {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify({ conductor: params.id })
                }).then((response) => response.json())
                .then(async (response) => {
                    console.log(response) 
                    setConductor((s) => ({ ...s, ...response.datos.conductor }))
                })
            ])       
        }
        return () => isSubscribed = false
    }, [refreshPrimeraCarga])

    return (
        <Fragment>
            {esEditar?<LoaderSeccion status={conductor}/>:''}
            <Breadcrumb icon={Box} title={'Conductores'} parent={['conductores', , esEditar?params.id : 'nuevo']} />
            <div className="container-fluid">
                <div className='row'>
                    <form id='formconductor' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => {
                                        navigate('/conductores')
                                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVO'} CONDUCTOR</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales del conductor</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Nombre (s)<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                placeholder="Nombre (s)"
                                                defaultValue={conductor ? conductor.NOMBRE_DRV : ''}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre (s))</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Apellidos<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_apellidos" name="txt_apellidos"
                                                type="text"
                                                placeholder="Apellidos"
                                                defaultValue={conductor ? conductor.APELLIDO_DRV : ''}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_apellidos" style={{ display: 'none' }}><strong>Falta completar (Apellidos)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Correo<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_correo" name="txt_correo"
                                                type="text"
                                                placeholder="Correo"
                                                defaultValue={conductor ? conductor.CORREO_DRV : ''}
                                               readOnly = {esEditar ? true : false}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_correo" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Perfil<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Perfil"
                                                placeholder="Seleccionar perfil"
                                                id="list_perfil"
                                                name="list_perfil"
                                                tipoValue={1}
                                                datos={[
                                                    {clave: "driver", label: "Conductor"},
                                                    {clave: "peoneta", label: "Asistente de conductor"}
                                                ]}
                                                value={conductor ? conductor.PERFIL_DRV : ''}
                                                // onChange={(datos) => {
                                                //     setConductor((s) => ({...s, PERFIL_DRV: datos.item.clave}))
                                                // }}
                                                disableDropdown={esEditar ? true : false}
                                                readOnly={esEditar ? true : false}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_perfil" style={{ display: 'none' }}><strong>Falta completar (Perfil)</strong></span>
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">DNI</label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_dni" name="txt_dni"
                                                type="text"
                                                placeholder="DNI"
                                                // defaultValue="Guías prepagadas"
                                                // readOnly
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_dni" style={{ display: 'none' }}><strong>Falta completar (DNI)</strong></span>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Teléfono</label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control" 
                                                id="txt_telefono" name="txt_telefono"
                                                placeholder='Teléfono'
                                                maxLength={10}
                                                onInput={(e) => Tools.formatoNumber(e)}
                                                defaultValue = {conductor ? conductor.TELEFONO_DRV : ''}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_telefono" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Número de licencia de conducir</label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_licencia" name="txt_licencia"
                                                type="text"
                                                placeholder="Número de licencia"
                                                defaultValue={conductor ? conductor.NUMERO_LICENCIA_DRV : ''}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_licencia" style={{ display: 'none' }}><strong>Falta completar (Número de licencia)</strong></span>
                                    </div>
                                   
                                    {/* <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Depósitos</label>
                                        <div className="input-group input-gpack2">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_depositos" name="txt_depositos"
                                                type="text"
                                                placeholder="Depósitos"
                                                // defaultValue="Guías prepagadas"
                                                // readOnly
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_depositos" style={{ display: 'none' }}><strong>Falta completar (Depósitos)</strong></span>
                                    </div> */}

                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">Descripción</label>
                                        <div className="input-group">
                                            <textarea style={{ width: '100%' }} rows={5} id='txt_desc' name='txt_desc' placeholder='Descripción' defaultValue={conductor ? conductor.DESCRIPCION_DRV : ''}></textarea>
                                        </div>
                                        <span className="text-danger txtVacios txt_desc" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>
                                    {esEditar ? 
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            <button
                                                className="btn btn-purple d-flex align-items-center"
                                                type="button"
                                                onClick={(e) => actualizar(conductor.ID_DRV)}
                                                disabled = {isUpdating}
                                            >
                                                {/* Actualizar conductor */}
                                                {isUpdating ? 'Editando...' : 'Editar conductor'}
                                            </button>
                                        </div>
                                    </div>
                                    : ''}

                                    {!esEditar ? <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            <button
                                                className="btn btn-purple d-flex align-items-center"
                                                type="button"
                                                onClick={(e) => guardar()}
                                                disabled = {isUpdating}

                                            >
                                                {/* Guardar conductor */}
                                                {isUpdating ? 'Guardando...' : 'Guardar conductor'}
                                                </button>
                                        </div>
                                    </div> : ''}

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default