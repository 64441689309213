import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import Select from '../common/select-basic'
import { Truck } from 'react-feather';
import notie from 'notie';
import Tools from '../../js/tools';
import SelectFetch from '../common/select-fetch';
//FORMULARIOS

import Breadcrumb from '../common/breadcrumbv2';    
import { useNavigate } from 'react-router-dom';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const Default = (props) => {
    const [catalogos, setCatalogos] = useState({});
    const navigate = useNavigate();
    const [addressSearched, setAddressSearched] = useState()
    const [selectedValue, setSelectedValue] = useState('')

    const libraries = ['places'];
    const googleMapsApiKey = 'AIzaSyDF71wZDEB-Et65Vnpa05ln296QekbbgMM';

    const AddressAutocomplete = () => {
        const autocompleteRef = useRef(null);

        const onLoad = useCallback((autocomplete) => {
            autocompleteRef.current = autocomplete;
        }, []);

        const onPlaceChanged = () => {
            const place = autocompleteRef.current.getPlace();
            setAddressSearched(place.formatted_address)
            
            const addressComponents = place.address_components;
            document.getElementById('input_longitud').value = place.geometry.location.lng()
            document.getElementById('input_latitud').value = place.geometry.location.lat()
            const parsedAddress = parseAddressComponents(addressComponents);

            populateAddressFields(parsedAddress)
        };

        const parseAddressComponents = (addressComponents) => {
            const address = {
                administrative_area_level_1: '',
                street_number: '',
                route: '',
                sublocality: '',
                postal_code: '',
                locality: '',
            };

            addressComponents.forEach(component => {
                const types = component.types;
                const value = component.long_name;

                if (types.includes('administrative_area_level_1')) {
                    address.administrative_area_level_1 = value;
                } else if (types.includes('street_number')) {
                    address.street_number = value;
                } else if (types.includes('route')) {
                    address.route = value;
                } else if (types.includes('sublocality')) {
                    address.sublocality = value;
                } else if (types.includes('postal_code')) {
                    address.postal_code = value;
                } else if (types.includes('locality')) {
                    address.locality = value;
                }
            });

            return address;
        };

        return (
            <>
                <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        <div className="input-group input-gpack2">
                            <input className="form-control" name='input_buscar' id='input_buscar' type="text" data-label="" value={addressSearched} />
                        </div>
                    </Autocomplete>
                </LoadScript>
            </>
        );
    };
    
    function populateAddressFields(addressComponents) {
        
        const componentForm = {
            administrative_area_level_1: 'select_estado',  // estado
            street_number: 'input_num_ext',
            route: 'input_calle',
            sublocality: 'input_colonia',
            postal_code: 'input_cp',
            locality: 'select_ciudad',  // ciudad
        };
    
        // Inicializar los valores de los inputs a vacío
        for (let component in componentForm) {
            document.getElementById(componentForm[component]).value = '';
        }

        document.getElementById('input_referencias').value = '';
    
        let cityComponent = null;
    
        for(let addressField in addressComponents){
            for(let component in componentForm){
                if(component == addressField){
                    if(component == 'administrative_area_level_1'){
                        for (let i = 0; i < document.getElementById(componentForm[component]).options.length; i++) {
                            if (document.getElementById(componentForm[component]).options[i].text === addressComponents[addressField]) {
                                document.getElementById(componentForm[component]).selectedIndex = i;
                                document.getElementById(componentForm[component]).dispatchEvent(new Event('change', { bubbles: true, cancelable: true, }));
                                break;
                            }
                        }
                    } else if (component == 'locality'){
                        cityComponent = addressComponents[addressField];
                    } else{
                        document.getElementById(componentForm[component]).value = addressComponents[addressField];
                    }
                }
            }
        }
        if (cityComponent) {
            setTimeout(() => {
                for (let i = 0; i < document.getElementById(componentForm['locality']).options.length; i++) {
                    if (document.getElementById(componentForm['locality']).options[i].text === cityComponent) {
                        document.getElementById(componentForm['locality']).selectedIndex = i;
                        break;
                    }
                }
            }, 1500);  // Puedes ajustar el tiempo según sea necesario
        }

    }

    const changeState = (e) => {
        console.log(e);
        let clave = (e);
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: {clave},
        }, (response) => {
            setCatalogos({...catalogos, ciudades: response.datos})
        })
    }

    const enviarDatosFormulario = () => {
        let datos = Tools.getForm('form_sucursal', {ignore: selectedValue.label === 'Física' ? [ 'input_num_int', 'input_referencias' ] : [ 'input_buscar', 'input_calle', 'input_num_ext', 'input_num_int', 'input_colonia', 'input_cp', 'select_estado', 'select_ciudad', 'input_referencias', 'input_longitud', 'input_latitud'] });
        console.log(2, datos);
        
        if(datos.formIsEmpty > 0){
            return notie.alert({text: `El campo ${datos.labelIsEmpty[datos.keys[0]]} es requerido`, type: 3, time: 4});
        }

        fetch('../rt_sucursales_nuevo', {
            method: 'POST',
            body: JSON.stringify(datos.data),
            headers: {'Content-Type': 'application/json'}
        }).then((res) => res.json())
        .then((res) => {
            console.log('123', res);
            
            if(res.estatus === 'OK'){
                if(res.suc.RESULTADO === 'AGREGADO'){
                    notie.alert({text: 'Agregado con éxito', type: 1, time: 4})
                    setTimeout(() => {
                        notie.alert({text: 'Será redireccionado a la sección de datos fiscales para vincular estos datos', type: 1, time: 4})
                        setTimeout(() => {
                            navigate('/empresa/datos-fiscales')
                        }, 4000);
                    }, 4000);
                }else{
                    notie.alert({text: 'La sucursal ya se encuentra registrada', type: 2, time: 5});
                }
            }else{
                notie.alert({text: 'No se pudo agregar, inténtelo más tarde.', type: 3, time: 5});
            }
        }).catch(function(err){
            console.log(err);
            
            notie.alert({text: 'No se pudo agregar, inténtelo más tarde', type: 3, time: 5});
        })
    }

    function validarNumeros(input) {
        input.target.value = input.target.value.replace(/[^0-9]/g, '');
    }

    function selectedType(e){
        console.log(e);
        
        let value = e.label
        if(value === "Física"){
            document.getElementById('form_address').removeAttribute('hidden')
        }else{
            setSelectedValue({value: e.value, label: e.label})
            document.getElementById('form_address').setAttribute('hidden', 'hidden')
        }
    }
    
    useEffect(() => {
        fetch('../rt_sucursales_catalogos', {
            method: 'POST',
            body: '{}',
            headers: {'Content-Type': 'application/json'}
        }).then(function(res) { return res.json(); })
        .then(function(res) {
            if(res.estatus === 'OK'){
                console.log(123, res.datos);
                
                setCatalogos(res.datos)
                console.log(321, catalogos);
                
            }
            else
                throw Error(res.estatus);
        }).catch(function(err){
            notie.alert({text: 'No se pudo obtener los catálogos, inténtelo más tarde', type: 4, time: 5});
        })
    }, [])
    
    return (
        <Fragment>
            <Breadcrumb icon={Truck} title={'Nueva sucursal'} parent={['sucursales', 'nuevo']}/>
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <form id='form_sucursal' className="theme-form">
                            {/* <div className='form-group row'>
                                <label className='col-sm-3 col-lg-2 col-form-label' htmlFor="select_empresa">Empresa</label>
                                <div className='col-sm-9 col-lg-10'>
                                    <select className='form-select' data-label='empresa' name='select_empresa' id='select_empresa'>
                                        <option hidden value={''}>Seleccione</option>
                                        {
                                            catalogos.empresas.map(empresa => 
                                            <option key={`key_option_empresas_${empresa.ID}`} value={empresa.ID}>{empresa.NOMBRE}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="select_tipo_suc">Tipo de sucursal<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <SelectFetch
                                            name={'select_tipo_suc'}
                                            id={'select_tipo_suc'}
                                            ruta='rt_ctg_tipo_suc'
                                            label={'Tipo de sucursal'}
                                            clave_cps={catalogos?.tipo_suc ? (catalogos?.tipo_suc.clave + '-' + catalogos?.tipo_suc.label) : ''}
                                            value={selectedValue ? { value: selectedValue.value, label: `${selectedValue.label}` } : ''}
                                            onValueChange={(value) => selectedType(value)}
                                        />
                                        {/* <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="tipo_suc"
                                            placeholder="Seleccione un tipo de sucursal"
                                            id="select_tipo_suc"
                                            name="select_tipo_suc"
                                            tipoValue={1}
                                            value={''}
                                            datos={catalogos.tipo_suc}
                                            onChange={(e) => selectedType(e)}
                                        /> */}
                                    </div>
                                    <span className="text-danger txtVacios select_tipo_suc" style={{ display: 'none' }}><strong>Falta completar (Tipo de sucursal)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_nombre">Nombre<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_nombre' id='input_nombre' type="text" data-label="" placeholder='Nombre de la sucursal'/>
                                    </div>
                                    <span className="text-danger txtVacios input_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre de la sucursal)</strong></span>
                                </div>
                                <div className='col-md-6'>
                                    <label className="col-form-label" htmlFor="input_correo">Correo<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_correo' id='input_correo' type="email" placeholder="Correo de contacto" />
                                    </div>
                                    <span className="text-danger txtVacios input_correo" style={{ display: 'none' }}><strong>Falta completar (Correo de contacto)</strong></span>
                                </div>
                                <div className='col-md-3'>
                                    <label className="col-form-label" htmlFor="input_telefono">Teléfono<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_telefono' id='input_telefono' type="text" placeholder="Teléfono de contacto" onChange={validarNumeros}/>
                                    </div>
                                    <span className="text-danger txtVacios input_telefono" style={{ display: 'none' }}><strong>Falta completar (Teléfono de contacto)</strong></span>
                                </div>
                                <div className='col-md-3'>
                                    <label className="col-form-label" htmlFor="input_celular">Celular de contacto<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_celular' id='input_celular' type="text" placeholder="Celular (Whatsapp)" onChange={validarNumeros}/>
                                    </div>
                                    <span className="text-danger txtVacios input_celular" style={{ display: 'none' }}><strong>Falta completar (Celular (Whatsapp))</strong></span>
                                </div>
                            </div>
                            <div className="form-group row" id='form_address' hidden>
                                <div className='col-md-12 mb-4'>
                                    <label className="col-form-label" htmlFor="input_buscar">Buscar dirección<span className="text-danger">*</span></label>
                                    <AddressAutocomplete/>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_calle">Calle<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_calle' id='input_calle' type="text" data-label="" placeholder='Calle'/>
                                    </div>
                                    <span className="text-danger txtVacios input_calle" style={{ display: 'none' }}><strong>Falta completar (Calle)</strong></span>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_num_ext">Número exterior<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_num_ext' id='input_num_ext' type="text" data-label="" placeholder='Numero exterior' />
                                    </div>
                                    <span className="text-danger txtVacios input_num_ext" style={{ display: 'none' }}><strong>Falta completar (Numero exterior)</strong></span>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_num_int">Número interior</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_num_int' id='input_num_int' type="text" data-label="" placeholder='Numero interior' />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_colonia">Colonia<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_colonia' id='input_colonia' type="text" data-label="" placeholder='Colonia' />
                                    </div>
                                    <span className="text-danger txtVacios input_colonia" style={{ display: 'none' }}><strong>Falta completar (Colonia)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_cp">Código postal<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_cp' id='input_cp' type="text" data-label="" placeholder='Código postal' />
                                    </div>
                                    <span className="text-danger txtVacios input_cp" style={{ display: 'none' }}><strong>Falta completar (Código postal)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className='col-form-label' htmlFor="select_estado">Estado</label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="estado"
                                            placeholder="Seleccione un Estado"
                                            id="select_estado"
                                            name="select_estado"
                                            tipoValue={1}
                                            value={''}
                                            datos={catalogos.estados}
                                            onChange={(e) => {changeState(e.e.target.value)}}
                                        />
                                    </div>
                                    <span className="text-danger txtVacios select_estado" style={{ display: 'none' }}><strong>Falta completar (Estado)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className='col-form-label' htmlFor="select_ciudad">Ciudad</label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="ciudad"
                                            placeholder="Seleccione una Ciudad"
                                            id="select_ciudad"
                                            name="select_ciudad"
                                            tipoValue={1}
                                            value={''}
                                            datos={catalogos.ciudades}
                                            onChange={() => null}
                                        />
                                    </div>
                                    <span className="text-danger txtVacios select_ciudad" style={{ display: 'none' }}><strong>Falta completar (Ciudad)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_referencias">Referencias de dirección</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_referencias' id='input_referencias' type="text" data-label="" placeholder='Referencias' />
                                    </div>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_longitud">Longitud de dirección<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_longitud' id='input_longitud' type="text" data-label="" placeholder='Longitud'/>
                                    </div>
                                    <span className="text-danger txtVacios input_longitud" style={{ display: 'none' }}><strong>Falta completar (Longitud de dirección)</strong></span>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_latitud">Latitud de dirección<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_latitud' id='input_latitud' type="text" data-label="" placeholder='Latitud'/>
                                    </div>
                                    <span className="text-danger txtVacios input_latitud" style={{ display: 'none' }}><strong>Falta completar (Latitud de dirección)</strong></span>
                                </div>                                
                            </div>
                            <button className='btn btn-purple' type='button' onClick={enviarDatosFormulario}>Guardar sucursal</button>
                        </form>
                    </div>
                </div>
            </div>

        </Fragment>)
}



export default Default;